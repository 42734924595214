<template>
  <transition name="slide-fade">
    <Card v-show="showTabs" class="p-col">
      <template #title>
        <div class="p-d-flex p-jc-between">
          <div>
            <h3 class="p-m-0"><strong>{{ parentData.name }}</strong></h3>
          </div>
        </div>
      </template>
      <template #content>
        <div class="p-grid">
          <div class="p-col-12">
            <div class="card">
              <TabView>
                <TabPanel :header="_t('menu.general')">
                  <div class="p-grid" v-for="(textTemplate, index) in textTemplates" v-bind:key="textTemplate.id">
                    <div  class="style p-col-11" :class="{ 'stripedBg': index % 2 === 0 }" >
                      <span style="vertical-align: middle;">{{textTemplate.text}}</span>
                    </div>
                    <div  class="style p-col-1" :class="{ 'stripedBg': index % 2 === 0 }" style="vertical-align: middle;">
                      <Button v-tooltip.bottom="_t('Edit')"
                              class="p-button-rounded p-button-text p-button-secondary"
                              icon="pi pi-pencil"
                              @click="editTextTemplate(textTemplate)"/>
                      <Button v-tooltip.bottom="_t('Delete')"
                              class="p-button-rounded p-button-danger p-button-text"
                              icon="pi pi-trash" @click="deleteTextTemplate(textTemplate.id)"/>
                    </div>
                  </div>
                </TabPanel>
              </TabView>
            </div>
          </div>
        </div>
      </template>
    </Card>
  </transition>
  <Dialog v-model:visible="editTextTemplateDialog" :style="{ width: '450px' }">
    <template #header>
      <h3>{{ _t('label_edit_text_template') }}</h3>
    </template>
    <div class="p-grid p-formgrid p-fluid">
      <div class="p-col-12 p-field">
        <div class="p-inputgroup">
          <span class="p-inputgroup-addon">
            <i class="pi pi-pencil"></i>
          </span>
          <Textarea
              v-model="selectedTextTemplate.text"
              :placeholder="_t('label_text_template')"
              class="p-m-0"
              rows="5"
              style="width:100%"/>

        </div>
      </div>
    </div>
    <div class="p-grid p-formgrid p-fluid">
      <div class="p-col-12 p-field">
        <div class="p-inputgroup">
          <MultiSelect ref="groupPG"
                       v-model="selectedTextTemplate.userGroupIds"
                       :options="userGroups"
                       optionValue="id"
                       optionLabel="name"
                       :filter="true"
                       :style="{width: '20vw'}"
          />
        </div>
      </div>
    </div>
    <template #footer>
      <Button :label="_t('Cancel')" class="p-button-outlined p-button-secondary" @click="editTextTemplateDialog = false"/>
      <Button :label="_t('Save')" autofocus class="p-button-success" @click="updateTextTemplate()"/>
    </template>
  </Dialog>
</template>

<script>
import NodeService from '@/service/NodeService'
import {
  removeItemById,
  update,
  getItemById, create
} from '@/api/text-templates'
import {getList} from "@/api/list";

export default {
  name: 'userGroupItem',
  props: {
    parentData: {
      type: Object,
      default() {
        return {
          id: null,
          name: ''

        }
      },
    }
  },
  data() {
    return {
      textTemplates: [],
      showTabs: false,
      selectedTextTemplate: {
        id: null,
        name: '',
        userGroupIds: []
      },
      editTextTemplateDialog: false,
      userGroups: null,
      UGTableRows: 100,
    }
  },
  watch: {
    parentData(value) {
      if (value !== null) {
        this.showTabs = true
        this.getTemplates()
      } else {
        this.showTabs = false
      }
    },
  },
  nodeService: null,
  created() {
    this.nodeService = new NodeService();
    this.getUserGroups()
  },
  methods: {
    getTemplates() {
      getItemById(this.parentData.id).then((response) => {
        this.textTemplates = response.data
      });
    },
    updateTextTemplate() {
      update(this.selectedTextTemplate.id, this.selectedTextTemplate.text, this.selectedTextTemplate.userGroupId).then(() => {
        this.getTemplates()
        this.editTextTemplateDialog = false
      });
      if (this.selectedTextTemplate.userGroupIds.length > 1) {
        this.selectedTextTemplate.userGroupIds = this.selectedTextTemplate.userGroupIds.filter(item =>  item !== this.selectedTextTemplate.userGroupId)
        create(this.selectedTextTemplate.text, this.selectedTextTemplate.userGroupIds).then((response) => {
          this.newTextTemplateDialog = false
          console.log(response)
        }).catch((error) => {
          console.log(error)
        })
      }
    },
    deleteTextTemplate(id) {
      removeItemById(id).then(() => {
        this.getTemplates()
      });
    },
    editTextTemplate(textTemplate)
    {

      this.selectedTextTemplate.id = textTemplate.id;
      this.selectedTextTemplate.text = textTemplate.text;
      this.selectedTextTemplate.userGroupId = this.parentData.id
      this.selectedTextTemplate.userGroupIds = [this.parentData.id]
      this.editTextTemplateDialog = true;
      console.log(this.parentData)
    },
    getUserGroups() {
      getList('USERGROUP', this.currentPage, '', this.UGTableRows, this.orgId).then((response) => {
        this.userGroups = response.data.list
        this.userGroups.sort((a, b) => a.name.localeCompare(b.name))
        this.currentPage = response.data.currentPage
        this.totalUGRecords = response.data.count
        this.loading = false
      })
    },
  }
}
</script>

<style lang="scss" scoped>

::v-deep(.p-treetable) {
  .p-treetable-tbody > tr > td {
    padding: 5px;

  }

  .p-treetable-thead > tr > th {
    padding: 5px;

  }

}

</style>
